import React, { useState } from "react";
import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";

const postLeave = async (range, employee, setLoading) => {
  try {
    setLoading(true);
    const response = await axios.post(
      `${base_url}calendar/block-calendar-by-range`,
      { range, employee },
      config
    );
    console.log(response.data);
    alert("leave updated")
    return response.data;
  } catch (error) {
    alert("An error occured")
    console.error("Error:", error);
    throw error;
  } finally {
    setLoading(false);
  }
};

const getLeave = async (month, employee, setLoading) => {
  try {
    setLoading(true);
    const response = await axios.get(
      `${base_url}employees/list-employee-onleave-inmonth/${employee}?date=${month}`
    );
    //alert("leave updated")
    return response.data;
  } catch (error) {
    //alert("An error occured")
    console.error("Error:", error);
    throw error;
  } finally {
    setLoading(false);
  }
};

const deleteLeave = async (range, employee, setLoading) => {
  try {
    setLoading(true);
    console.log(
      'range => ', range,
      'employee => ', employee,
      'setLoading => ', setLoading, 
    )
    const response = await axios.post(
      `${base_url}calendar/remove-blocked-calendar-by-range`,
      { range, employee },
      config
    );
    console.log(response.data);
    alert("leave removed")
    return response.data;
  } catch (error) {
    alert("An error occured")
    console.error("Error:", error);
    throw error;
  } finally {
    setLoading(false);
  }
};


const CalendarService = {
  getLeave,
  postLeave,
  deleteLeave
};

export default CalendarService;
