import { useEffect } from "react";
import CustomInput from "../components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { createCoupon, editCoupon, getCoupons, getCouponsById, resetState } from "../features/coupon/couponSlice";

//Yup schema
let schema = yup.object({
  CouponName: yup.string().required("Coupon name is required"),
  discountPrice: yup.number().required("Discount is required"),
  Status: yup.boolean().required("Status is required"),
});

const AddCoupon = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { couponId } = useParams()

  const singleCoupon = useSelector((state) => state.coupon.singleCoupon);

  const newCoupon = useSelector((state) => state.coupon);
  
  const { isLoading, isSuccess, isError, createdCoupon } = newCoupon;

  useEffect(() => {
    if (isSuccess && createdCoupon) {
      toast.success("Coupon Added Successfully!");
      dispatch(resetState());
      formik.resetForm();
      navigate("/admin/coupon-list");
    }
    if (isError) {
      toast.error("Something went wrong!");
    }
  }, [isLoading, isSuccess, isError, createdCoupon]);

  useEffect(() => {
    if(couponId){
      dispatch(getCouponsById(couponId));
    }
  }, [dispatch, couponId]);

  const formik = useFormik({
    initialValues: {
      CouponName: "",
      discountPrice: "",
      Status: "",
    },
    validationSchema: schema,

    onSubmit: (values) => {
      values.Status = values.Status === 'true' ? true : false
      console.log(values);
      if(couponId){
        dispatch(editCoupon({couponId, values}));
      }else{
        dispatch(createCoupon(values));
      }
      // //alert(JSON.stringify(values));
      // formik.resetForm();

      // setTimeout(() => {
      //   dispatch(resetState());
      //   // navigate("/admin/coupon-list");
      // }, 3000);
    },
  });
  useEffect(() => {
    if(singleCoupon){
      formik.setValues({
        CouponName: singleCoupon?.data.CouponName,
        discountPrice: singleCoupon?.data.discountPrice,
        Status: singleCoupon?.data.Status,
      })
    }
  },[singleCoupon])
  return (
    <div>
      <h3 className="mb-4 title">
        {couponId ? 'Edit Coupon' : 'Add Coupon'}
      </h3>
      <div>
        <form action="" onSubmit={formik.handleSubmit}>
          {/* Coupon Name */}
          <CustomInput
            type="text"
            label="Enter Coupon Name"
            name="CouponName"
            onCh={formik.handleChange("CouponName")}
            onBl={formik.handleBlur("CouponName")}
            val={formik.values.CouponName}
          />
          <div className="error">
            {formik.touched.CouponName && formik.errors.CouponName}
          </div>
          {/* Discount */}
          <CustomInput
            type="number"
            label="Enter Discount"
            name="discountPrice"
            onCh={formik.handleChange("discountPrice")}
            onBl={formik.handleBlur("discountPrice")}
            val={formik.values.discountPrice}
          />
          <div className="error">
            {formik.touched.discountPrice && formik.errors.discountPrice}
          </div>
          
          {/* Select Status */}
          <select
            name="Status"
            onChange={formik.handleChange("Status")}
            onBlur={formik.handleBlur("Status")}
            value={formik.values.Status}
            className="form-select form-select-sm mt-3"
          >
            <option value={""}>Status</option>
            {[
              {title: 'active',value: true},
              {title: 'in-active',value: false}
            ].map((item, i) => (
              <option key={i} value={item.value}>
                {item.title}
              </option>
            ))};
          </select>
          {/* <CustomInput
            type="text"
            label="Select Status"
            name="Status"
            onCh={formik.handleChange("Status")}
            onBl={formik.handleBlur("Status")}
            val={formik.values.Status}
          /> */}
          <div className="error">
            {formik.touched.Status && formik.errors.Status}
          </div>


          <button className="btn btn-primary border-0 rounded-3 my-4">
            {couponId ? 'Update Coupon' : 'Add Coupon'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddCoupon;
