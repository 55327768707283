import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url, BASE_URL } from "../../utils/baseUrl";

const getProducts = async () => {
  const response = await axios.get(`${BASE_URL}products/list?limit=40`);
  //console.log(response.data);
  return response.data;
};

const getProduct = async (id) => {
  const response = await axios.get(`${BASE_URL}products/${id}`);
  //console.log(response.data);
  return response.data;
};

const getOrder = async (id) => {
  const response = await axios.get(`${BASE_URL}orders/list/${id}`);
  //console.log(response.data);
  return response.data;
};

const createProduct = async (product) => {
  const response = await axios.post(`${BASE_URL}products/add-product`, product, config);
  //console.log(response.data);
  return response.data;
};

const editProduct = async (id, product) => {
  const response = await axios.patch(`${BASE_URL}products/edit-product/${id}`, product, config);
  //console.log(response.data);
  return response.data;
};

const deleteProduct = async (productId) => {
  const response = await axios.delete(`${BASE_URL}products/delete-product/${productId}`);
  //console.log(response.data);
  return response.data;
};

const productService = {
  getProducts,
  getProduct,
  getOrder,
  createProduct,
  editProduct,
  deleteProduct
};

export default productService;
