import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url, BASE_URL } from "../../utils/baseUrl";

const getEmployees = async () => {
  const response = await axios.get(`${BASE_URL}employees/list-all?limit=30`);
  console.log('getEmployee => ',response.data);
  return response.data;
};

const getEmployee = async (id) => {
  const response = await axios.get(`${BASE_URL}employees/list/${id}`);
  //console.log(response.data);
  return response.data;
};

const createEmployee = async (employee) => {
  const response = await axios.post(`${BASE_URL}employees/create`, employee, config);
  //console.log(response.data);
  return response.data;
};

const editEmployee = async (id, employee) => {
  const response = await axios.post(`${BASE_URL}employees/edit/${id}`, employee, config);
  console.log('edit details res => ', response.data);
  return response.data;
};

const deleteEmployee = async (employeeId) => {
  const response = await axios.get(`${BASE_URL}employees/deactivate-employee/${employeeId}`);
  //console.log(response.data);
  return response.data;
};

const employeeService = {
  getEmployees,
  getEmployee,
  createEmployee,
  editEmployee,
  deleteEmployee
};

export default employeeService;
