import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { BiEdit, BiTrash } from "react-icons/bi";
import { getOrder } from "../features/product/productSlice";
import { useParams } from "react-router-dom";

const OrderDetail = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams()
  console.log(orderId)  
  useEffect(() => {
    dispatch(getOrder(orderId));
  }, [dispatch]);

  const orderDetail = useSelector((state) => state.product.singleOrder);
  console.log(orderDetail)

   // Define columns for the Ant Design table
   const columns = [
    { title: 'Product Name', dataIndex: 'Name', key: 'name' },
    { title: 'Quantity', dataIndex: 'Quantity', key: 'quantity' },
    { title: 'SubTotal', dataIndex: 'SubTotal', key: 'subtotal' }
  ];

  // Prepare data for the table
  const data = orderDetail?.data?.Products.map((product) => ({
    key: product._id,
    Name: product.Product.name, 
    Quantity: product.Quantity,
    SubTotal: product.SubTotal
  }));

  return (
    <div>
      <h3 className="mb-4 title">Order detail</h3>
      <div className="order-details-container">
      <p>Order ID: {orderDetail?.data?.OrderID}</p>
      <p>Payment Status: {orderDetail?.data?.PaymentStatus}</p>
      <p>Installation Date: {orderDetail?.data?.InstallationDate}</p>
      <p>Customer Name: {orderDetail?.data?.FirstName} {orderDetail?.data?.LastName}</p>
      <p>Email: {orderDetail?.data?.Email}</p>
      <p>Contact Number: {orderDetail?.data?.ContactNumber}</p>
      <p>Address: {orderDetail?.data?.HouseNumber}, {orderDetail?.data?.StreetName}, {orderDetail?.data?.Town}, {orderDetail?.data?.County}, {orderDetail?.data?.PostCode}</p>
      <h5 className="mt-3">Total Price: {orderDetail?.data?.TotalPrice}</h5>
      <h4 className="mt-3">Products</h4>
      <Table columns={columns} dataSource={data} pagination={false}/>
      <h4 className="mt-3">Quiz Answers</h4>
      {orderDetail && orderDetail.data && orderDetail.data.Quiz && JSON.parse(orderDetail.data.Quiz).map((quizItem) => (
        <div className="quiz-item" key={quizItem.question}>
          <p>Question: {quizItem.question}</p>
          <p>Answer: {quizItem.answer}</p>
        </div>
      ))}

      </div>
    </div>
  );
};

export default OrderDetail;
