import React, { useEffect, useState } from "react";
import { Button, Spin } from 'antd';
import CustomInput from "../components/CustomInput";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import { uploadImg } from "../features/upload/uploadSlice";
import { createProduct, resetState, editProduct, getProduct } from "../features/product/productSlice";
import { BASE_URL } from "../utils/baseUrl";

//Yup schema
let schema = yup.object({
  name: yup.string().required("Name is required"),
  description: yup.string().required("Description is required"),
  price: yup.number().required("Price is required"),
  productType: yup.string().required("Product type is required"),
  image: yup.string().required("Image is required"),
}).when("productType", {
  is: (value) => value === "main",
  then: yup.object({
    tags: yup.string().required("Tag is required"),
  quantity: yup.number().required("Quantity is required"),
  width: yup.string().required("This field is required"),
  height: yup.string().required("This field is required"),
  depth: yup.string().required("This field is required"),
  warranty: yup.string().required("This field is required"),
    boiler_type: yup.string().required("This field is required"),
    fuel_type: yup.string().required("This field is required"),
    hot_water_flow_rate: yup.string().required("This field is required"),
    central_heating_output: yup.string().required("This field is required"),
    hot_water_output: yup.string().required("This field is required"),
    efficiency_rating: yup.string().required("This field is required"),
  }),
});

const AddProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productId } = useParams()

  const singleProduct = useSelector((state) => state.product.singleProduct);

  const { images: imageState, isLoading: imageLoading } = useSelector((state) => state.upload);
  const img = [];
  imageState.forEach((i) => img.push({ url: i }));

  useEffect(() => {
    if(img.length){
      formik.values.image = img[0].url;
    }
  }, [img]);

  const newProduct = useSelector((state) => state.product);
  const { isLoading, isSuccess, isError, createdProduct } = newProduct;

  useEffect(() => {
    if (isSuccess && createdProduct) {
      toast.success("Product Added Successfully!");
      dispatch(resetState());
      formik.resetForm();
      navigate("/admin/product-list");
    }
    if (isError) {
      toast.error("Something went wrong!");
    }
  }, [isLoading, isSuccess, isError, createdProduct]);

  useEffect(() => {
    if(productId){
      dispatch(getProduct(productId));
    }
  }, [dispatch, productId]);

  const formik = useFormik({
    initialValues: {
      name: "",
      brand: "",
      description: "",
      price: "",
      tags: "",
      quantity: 1,
      image: "",
      productType: "main",
      width: "",
      height: "",
      depth: "",
      warranty: "",
      boiler_type: "",
      fuel_type: "",
      hot_water_flow_rate: "",
      central_heating_output: "",
      hot_water_output: "",
      efficiency_rating: ""
    },
    validationSchema: schema,

    onSubmit: (values) => {
      console.log(values);
      if(productId){
        dispatch(editProduct({productId, values}))
      }else{
        dispatch(createProduct(values));
      }
      //alert(JSON.stringify(values));
      //toast.success("Product Added Successfully!");
    },
  });

  useEffect(()=>{
    console.log(singleProduct)
    if(singleProduct){
     formik.setValues({
       name: singleProduct?.data.name,
       brand: singleProduct?.data.brand,
       description: singleProduct?.data.description,
       price: singleProduct?.data.price,
       tags: singleProduct?.data.tags,
       quantity: singleProduct?.data.quantity,
       image: singleProduct?.data.image,
       productType: singleProduct?.data.productType,
       width: singleProduct?.data.width,
       height: singleProduct?.data.height,
       depth: singleProduct?.data.depth,
       warranty: singleProduct?.data.warranty,
       boiler_type: singleProduct?.data.boiler_type,
       fuel_type: singleProduct?.data.fuel_type,
       hot_water_flow_rate: singleProduct?.data.hot_water_flow_rate,
       central_heating_output: singleProduct?.data.central_heating_output,
       hot_water_output: singleProduct?.data.hot_water_output,
       efficiency_rating: singleProduct?.data.efficiency_rating
     });
    }
 }, [singleProduct])

  return (
    <div>
      <h3 className="mb-4 title">
        { productId ? 'Edit Product' : 'Add Product'  }
      </h3>

      <form onSubmit={formik.handleSubmit} className="d-flex flex-column gap-3">
        {/* Title */}
        <CustomInput
          type="text"
          label="Enter Product Name"
          name="name"
          onCh={formik.handleChange("name")}
          onBl={formik.handleBlur("name")}
          val={formik.values.name}
        />
        <div className="error">
          {formik.touched.name && formik.errors.name}
        </div>
         {/* Select Product type */}
         <select
          name="productType"
          onChange={formik.handleChange("productType")}
          onBlur={formik.handleBlur("productType")}
          value={formik.values.productType}
          className="form-select form-select-sm"
        >
          <option value="main">Main Product</option>
          <option value="additional">Additional Product</option>
        </select>
        <div className="error">
          {formik.touched.productType && formik.errors.productType}
        </div>
        {/* Description */}
        <div>
          <ReactQuill
            theme="snow"
            name="description"
            onChange={formik.handleChange("description")}
            value={formik.values.description}
          />
        </div>
        <div className="error">
          {formik.touched.description && formik.errors.description}
        </div>

        {/* Price */}
        <CustomInput
          type="number"
          label="Enter Product Price"
          name="price"
          onCh={formik.handleChange("price")}
          onBl={formik.handleBlur("price")}
          val={formik.values.price}
        />
        <div className="error">
          {formik.touched.price && formik.errors.price}
        </div>
        { formik.values.productType === 'main' &&
        (
        <>
        <CustomInput
          type="text"
          label="Enter Brnad Name"
          name="brand"
          onCh={formik.handleChange("brand")}
          onBl={formik.handleBlur("brand")}
          val={formik.values.brand}
        />
        <div className="error">
          {formik.touched.brand && formik.errors.brand}
        </div>
        <CustomInput
          type="text"
          label="Width (mm)"
          name="width"
          onCh={formik.handleChange("width")}
          onBl={formik.handleBlur("width")}
          val={formik.values.width}
        />
        <div className="error">
          {formik.touched.width && formik.errors.width}
        </div>
        <CustomInput
          type="text"
          label="Height (mm)"
          name="height"
          onCh={formik.handleChange("height")}
          onBl={formik.handleBlur("height")}
          val={formik.values.height}
        />
        <div className="error">
          {formik.touched.height && formik.errors.height}
        </div>
        <CustomInput
          type="text"
          label="Depth (mm)"
          name="depth"
          onCh={formik.handleChange("depth")}
          onBl={formik.handleBlur("depth")}
          val={formik.values.depth}
        />
        <div className="error">
          {formik.touched.depth && formik.errors.depth}
        </div> 
        <CustomInput
          type="text"
          label="Warranty"
          name="warranty"
          onCh={formik.handleChange("warranty")}
          onBl={formik.handleBlur("warranty")}
          val={formik.values.warranty}
        />
        <div className="error">
          {formik.touched.warranty && formik.errors.warranty}
        </div>
        <CustomInput
          type="text"
          label="Boiler Type"
          name="boiler_type"
          onCh={formik.handleChange("boiler_type")}
          onBl={formik.handleBlur("boiler_type")}
          val={formik.values.boiler_type}
        />
        <div className="error">
          {formik.touched.boiler_type && formik.errors.boiler_type}
        </div>
        <CustomInput
          type="text"
          label="Fuel Type"
          name="fuel_type"
          onCh={formik.handleChange("fuel_type")}
          onBl={formik.handleBlur("fuel_type")}
          val={formik.values.fuel_type}
        />
        <div className="error">
          {formik.touched.fuel_type && formik.errors.fuel_type}
        </div>
        <CustomInput
          type="text"
          label="Hot Water Flow Rate"
          name="hot_water_flow_rate"
          onCh={formik.handleChange("hot_water_flow_rate")}
          onBl={formik.handleBlur("hot_water_flow_rate")}
          val={formik.values.hot_water_flow_rate}
        />
        <div className="error">
          {formik.touched.hot_water_flow_rate && formik.errors.hot_water_flow_rate}
        </div>
        <CustomInput
          type="text"
          label="Central heating output"
          name="central_heating_output"
          onCh={formik.handleChange("central_heating_output")}
          onBl={formik.handleBlur("central_heating_output")}
          val={formik.values.central_heating_output}
        />
        <div className="error">
          {formik.touched.central_heating_output && formik.errors.central_heating_output}
        </div>
        <CustomInput
          type="text"
          label="Hot Water Output"
          name="hot_water_output"
          onCh={formik.handleChange("hot_water_output")}
          onBl={formik.handleBlur("hot_water_output")}
          val={formik.values.hot_water_output}
        />
        <div className="error">
          {formik.touched.hot_water_output && formik.errors.hot_water_output}
        </div>
        <CustomInput
          type="text"
          label="Efficiency Rating"
          name="efficiency_rating"
          onCh={formik.handleChange("efficiency_rating")}
          onBl={formik.handleBlur("efficiency_rating")}
          val={formik.values.efficiency_rating}
        />
        <div className="error">
          {formik.touched.efficiency_rating && formik.errors.efficiency_rating}
        </div>
        <select
          name="tags"
          onChange={formik.handleChange("tags")}
          onBlur={formik.handleBlur("tags")}
          value={formik.values.tags}
          className="form-select form-select-sm"
        >
          <option value="" disabled>
            Select Tags
          </option>
          <option value="featured">Featured</option>
          <option value="popular">Popular</option>
          <option value="special">Special</option>
        </select>
        <div className="error">
          {formik.touched.tags && formik.errors.tags}
        </div>
        {/* Select Color */}
        {/* <Select
          mode="multiple"
          allowClear
          className="w-100 text-dark"
          placeholder="Select Color"
          defaultValue={color}
          onChange={(i) => handleColor(i)}
          options={colorOption}
        />
        <div className="error">
          {formik.touched.color && formik.errors.color}
        </div> */}

        {/* Enter Quantity */}
        <CustomInput
          type="number"
          label="Enter Quantity"
          name="quantity"
          onCh={formik.handleChange("quantity")}
          onBl={formik.handleBlur("quantity")}
          val={formik.values.quantity}
        />
        <div className="error">
          {formik.touched.quantity && formik.errors.quantity}
        </div>
        </>)
        }

        {/* Drop image files */}
        <div className="bg-white border-1 p-5 text-center">
          <Dropzone
            onDrop={(acceptedFiles) => dispatch(uploadImg(acceptedFiles))}
            disabled={imageLoading}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
        <div className="error">
          {formik.errors.image && formik.errors.image}
        </div>

        {/* Show images */}
        <div className="showimages d-flex flex-wrap gap-3">
          {imageState.map((item, i) => (
            <div key={i} className="position-relative">
              {/* <button
                type="button"
                onClick={() => dispatch(deleteImg(item.public_id))}
                className="btn-close position-absolute top-0 end-0 border-0 bg-white rounded-circle shadow-sm p-0 m-0 text-dark fs-5 fw-bold"
                // style={{ top: "5px", right: "5px" }}
              ></button> */}
              <img src={`${BASE_URL}${item}`} alt="" className="img-fluid" width={200} />
            </div>
          ))}
          {imageLoading && <Spin />}
          {imageState.length < 1 && singleProduct &&
            <div className="position-relative">
              <img src={`${BASE_URL}${singleProduct.data.image}`} alt="" className="img-fluid" width={200} />
            </div>
          }
        </div>

        <Button
          className="btn btn-primary border-0 rounded-3 my-4"
          type="primary"
          htmlType="submit"
          loading={isLoading || imageLoading}
        >
          { productId ? 'Update Product' : 'Add Product' }
        </Button>
      </form>
    </div>
  );
};

export default AddProduct;
