import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { BiEdit, BiTrash } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getProducts, deleteProduct } from "../features/product/productSlice";
import axios from "axios";
import { base_url } from "../utils/baseUrl";

const columns = [
  {
    title: "#",
    dataIndex: "key",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: "Name",
    dataIndex: "name",
    sorter: (a, b) => b.name.localeCompare(a.name),
    sortDirections: ["descend"],
  },
  {
    title: "Price",
    dataIndex: "price",
    sorter: (a, b) => a.price.length - b.price.length,
    sortDirections: ["descend"],
  },
  {
    title: "Qty",
    dataIndex: "quantity",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.quantity - b.quantity,
  },
  // {
  //   title: "Category",
  //   dataIndex: "category",
  //   sorter: (a, b) => b.title.localeCompare(a.title),
  //   sortDirections: ["descend"],
  // },
  // {
  //   title: "Brand",
  //   dataIndex: "brand",
  //   sorter: (a, b) => b.title.localeCompare(a.title),
  //   sortDirections: ["descend"],
  // },
  // {
  //   title: "Color",
  //   dataIndex: "color",
  // },
  {
    title: "Action",
    dataIndex: "action",
  },
];

const ProductList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  // const deleteProduct = async (productId) => {
  //   const product_delete = await axios.delete(`${base_url}products/delete-product/${productId}`)
  //   console.log('product_delete => ',product_delete)
  // }

  const productState = useSelector((state) => state.product.products);

  const data1 = [];
  if(productState && productState.data && productState.data.length){
    for (let i = 0; i < productState.data.length; i++) {
      data1.push({
        key: i + 1,
        name: productState.data[i].name,
        price: `€${productState.data[i].price}`,
        quantity: productState.data[i].quantity,
        action: (
          <>
            <Link className="text-danger fs-5" to={`/admin/edit-product/${productState.data[i]._id}`}>
              <BiEdit />
            </Link>
            <span className="text-danger ms-3 fs-5">
              <BiTrash onClick={() => {
                  dispatch(deleteProduct(productState.data[i]._id))
                setTimeout(() => {
                  dispatch(getProducts())
                }, 1000)
                }}/>
            </span>
          </>
        ),
      });
    }
  }

  return (
    <div>
      <h3 className="mb-4 title">Products</h3>
      <div>
        <Table columns={columns} dataSource={data1} />
      </div>
    </div>
  );
};

export default ProductList;
