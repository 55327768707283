import React, { useEffect, useState } from "react";
import { Button, Col, Row } from 'antd';
import CustomInput from "../components/CustomInput";
import { useNavigate, useParams } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import Calendar from 'react-calendar';
import { useDispatch, useSelector } from "react-redux";
import { createEmployee, resetState, editEmployee, getEmployee } from "../features/employee/employeeSlice";
import 'react-calendar/dist/Calendar.css';
import calendarService from "../features/calendar/calendarService"

// type ValuePiece = Date | null;

// type Value = ValuePiece | [ValuePiece, ValuePiece];

//Yup schema
let schema = yup.object({
  FirstName: yup.string().required("First Name is required"),
  LastName: yup.string().required("Last Name is required"),
  Email: yup.string().required("Email is required"),
  Address: yup.string().required("Address type is required"),
  PhoneNumber: yup.number().required("Phone Number is required"),
});

const AddEmployee = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { employeeId } = useParams()

  const [value, onChange] = useState(null);
  const [leaveValue, onChangeLeave] = useState(null);
  const [loading, setLoading] = useState(false)
  const [leave, setLeave] = useState([])
  const singleEmployee = useSelector((state) => {
    return state.employee.singleEmployee
  });
  const today = new Date()
  const newEmployee = useSelector((state) => state.employee);
  const { isLoading, isSuccess, isError, createdEmployee } = newEmployee;

  useEffect(() => {
    if (isSuccess && createdEmployee) {
      toast.success("Employee Added Successfully!");
      dispatch(resetState());
      formik.resetForm();
      navigate("/admin/employee-list");
    }
    if (isError) {
      toast.error("Something went wrong!");
    }
  }, [isLoading, isSuccess, isError, createdEmployee]);


  useEffect(() => {
    if(employeeId){
      dispatch(getEmployee(employeeId));
    }
  }, [dispatch, employeeId]);

  const handleLeave = () => {

    calendarService.postLeave(value, employeeId, setLoading)
  }
  const RemoveLeave = () => {

    calendarService.deleteLeave(leaveValue, employeeId, setLoading)
  }

  async function getEmpLeave (dateString){
    const empLeave = await calendarService.getLeave(dateString, employeeId, setLoading)
    setLeave(empLeave)
  }

  useEffect(() => {
    if (today) {
      const month =
        today.getMonth() + 1 > 9
          ? today.getMonth() + 1
          : '0' + (today.getMonth() + 1)

      const dateString = `${month}-${today.getFullYear()}`
      getEmpLeave(dateString)
    }
  }, [])

  const handleMonthChange = ({ activeStartDate }) => {
    const month =
      activeStartDate.getMonth() + 1 > 9
        ? activeStartDate.getMonth() + 1
        : '0' + (activeStartDate.getMonth() + 1)

    const dateString = `${month}-${activeStartDate.getFullYear()}`
    getEmpLeave(dateString)
  }

  const formik = useFormik({
    initialValues: {
      FirstName: "",
      LastName: "",
      Email: "",
      Address: "",
      PhoneNumber: "",
    },
    validationSchema: schema,

    onSubmit: (values) => {
      if(employeeId){
        dispatch(editEmployee({employeeId, values}))
      }else{
        dispatch(createEmployee(values));
      }
    },
  });

  useEffect(()=>{
    if(singleEmployee){
     formik.setValues({
       FirstName: singleEmployee?.data.FirstName,
       LastName: singleEmployee?.data.LastName,
       Email: singleEmployee?.data.Email,
       Address: singleEmployee?.data.Address,
       PhoneNumber: singleEmployee?.data.PhoneNumber,
     });
    }
 }, [singleEmployee])

  return (
    <div>
      <h3 className="mb-4 title">
        { employeeId ? 'Edit Employee' : 'Add Employee'  }
      </h3>

      <form onSubmit={formik.handleSubmit} className="d-flex flex-column gap-3">
        {/* First Name */}
        <CustomInput
          type="text"
          label="Enter Employee First Name"
          name="FirstName"
          onCh={formik.handleChange("FirstName")}
          onBl={formik.handleBlur("FirstName")}
          val={formik.values.FirstName}
        />
        <div className="error">
          {formik.touched.FirstName && formik.errors.FirstName}
        </div>

        {/* Last Name */}
        <CustomInput
          type="text"
          label="Enter Employee Last Name"
          name="LastName"
          onCh={formik.handleChange("LastName")}
          onBl={formik.handleBlur("LastName")}
          val={formik.values.LastName}
        />
        <div className="error">
          {formik.touched.LastName && formik.errors.LastName}
        </div>

        {/* Email */}
        <CustomInput
          type="text"
          label="Enter Employee Email Address"
          name="Email"
          onCh={formik.handleChange("Email")}
          onBl={formik.handleBlur("Email")}
          val={formik.values.Email}
        />
        <div className="error">
          {formik.touched.Email && formik.errors.Email}
        </div>

        {/* Address */}
        <CustomInput
          type="text"
          label="Enter Employee Address"
          name="Address"
          onCh={formik.handleChange("Address")}
          onBl={formik.handleBlur("Address")}
          val={formik.values.Address}
        />
        <div className="error">
          {formik.touched.Address && formik.errors.Address}
        </div>
        
        {/* Phone Number */}
        <CustomInput
          type="number"
          label="Enter Employee Phone Number"
          name="PhoneNumber"
          onCh={formik.handleChange("PhoneNumber")}
          onBl={formik.handleBlur("PhoneNumber")}
          val={formik.values.PhoneNumber}
        />
        <div className="error">
          {formik.touched.PhoneNumber && formik.errors.PhoneNumber}
        </div>
        { employeeId && 
          <Row  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={24} lg={{ span:12 }}>
            <h3>Add Leave</h3>
            <div style={{ pointerEvents: loading ? 'none' : '', filter: loading ? 'blur(10px)' : ''  }}>
              <Calendar selectRange minDate={today} onChange={onChange} value={value} />
            </div>
            <Button onClick={handleLeave} loading={loading}>Update Leave</Button>
          </Col>
          <Col span={24} lg={{ span:12 }}>
          <h3>Remove Leave</h3>
            <div style={{ pointerEvents: loading ? 'none' : '', filter: loading ? 'blur(10px)' : ''  }}>
              
              <Calendar selectRange minDate={today} onChange={onChangeLeave} value={leaveValue} 
                showNeighboringMonth={false}
                onActiveStartDateChange={handleMonthChange}
              tileDisabled={({ date }) => {
                const val = date.getDate() > 9 ? String(date.getDate()) : `0${date.getDate()}`
                if(leave.data && leave.data.includes(val)){
                  return false
                }else{
                  return true
                }
                // return true
              }} 
            />

            </div>
            <Button onClick={RemoveLeave} loading={loading}>Remove Leave</Button>
          </Col>
        </Row>
        }
        <Button
          className="btn btn-primary border-0 rounded-3 my-4"
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          { employeeId ? 'Update Employee' : 'Add Employee' }
        </Button>
      </form>
    </div>
  );
};

export default AddEmployee;
