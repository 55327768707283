import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { AiFillEye } from "react-icons/ai";
//import { getUserOrders } from "../features/auth/authSlice";
import { getAllOrders } from "../features/auth/authSlice";

const columns = [
  {
    title: "#",
    dataIndex: "key",
  },
  {
    title: "Cteated At",
    dataIndex: "createdAt",
    sorter: (a, b) => b.createdAt.localeCompare(a.createdAt),
    sortDirections: ["descend"],
  },
  {
    title: "Name",
    dataIndex: "name",
    sorter: (a, b) => b.name.localeCompare(a.name),
    sortDirections: ["descend"],
  },
  {
    title: "Products",
    dataIndex: "product",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    sorter: (a, b) => a.amount - b.amount,
    sortDirections: ["descend"],
  },
  {
    title: "Installation Date",
    dataIndex: "install_date",
    sorter: (a, b) => a.amount - b.amount,
    sortDirections: ["descend"],
  },

  {
    title: "Action",
    dataIndex: "action",
  },
];

const Orders = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrders());
  }, [dispatch]);

  const orderState = useSelector((state) => state.auth.orders);
  console.log('orderState => ',orderState)
  const data1 = [];
  if(orderState.data){
    for (let i = 0; i < orderState.data.length; i++) {
      data1.push({
        key: i + 1,
  
        createdAt: new Date(orderState.data[i].createdAt).toLocaleString(),
        // `${orderState[i].createdAt.toString().split("T")[0]} ${
        //   orderState[i].createdAt.toString().split("T")[1].split(".")[0]}`,
        name: (
          <>
            <p>{`${orderState.data[i].FirstName} ${orderState.data[i].LastName}`}</p>
          </>
        ),
  
        product: orderState.data[i].Products[0].Product?.name,
        // .map((product) => {
        //   return (
        //     <p key={product._id}>
        //       <Link to={`/order/${orderState.data[i]._id}`}>
        //         {product.name}
        //       </Link>
        //     </p>
        //   );
        // }),
  
        amount: `€${orderState.data[i].TotalPrice}`,

        install_date: (<p>
          {
            new Date(Date.now()) < new Date(orderState.data[i].InstallationDate) ? 
            <p style={{color: 'green'}}> {new Date(orderState.data[i].InstallationDate).toLocaleString().split(',')[0]} </p> 
            : <p style={{color: 'red'}}> {new Date(orderState.data[i].InstallationDate).toLocaleString().split(',')[0]} </p>
          }
        </p>),
  
        action: (
          <>
            <Link className="text-danger fs-5" to={`/admin/order/${orderState.data[i].OrderID}`}>
              <AiFillEye />
            </Link>
            {/* <Link className="text-danger ms-3 fs-5" to="/">
              <BiTrash />
            </Link> */}
          </>
        ),
      });
    }

  }
  return (
    <div>
      <h3 className="mb-4 title">Orders</h3>
      <div>
        <Table columns={columns} dataSource={data1} />
      </div>
    </div>
  );
};

export default Orders;
