import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit"
import employeeService from "./employeeService";

export const getEmployees = createAsyncThunk(
  "employee/get-employees",
  async (thunkAPI) => {
    try {
      const response = await employeeService.getEmployees();
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getEmployee = createAsyncThunk(
  "employee/get-employee",
  async (id, thunkAPI) => {
    try {
      const response = await employeeService.getEmployee(id);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createEmployee = createAsyncThunk(
  "employee/create-employee",
  async (data, thunkAPI) => {
    try {
      const response = await employeeService.createEmployee(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const editEmployee = createAsyncThunk(
  "employee/edit-employee",
  async (data, thunkAPI) => {
    console.log('inside', data)
    try {
      const response = await employeeService.editEmployee(data.employeeId, data.values);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteEmployee = createAsyncThunk(
  "employee/delete-employee",
  async (employeeId, thunkAPI) => {
    try {
      console.log('employeeId => ', employeeId)
      const response = await employeeService.deleteEmployee(employeeId);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const resetState = createAction("Reset_all");

const initialState = {
  employees: [],
  singleEmployee: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: "",
};

export const employeeSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEmployees.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    });
    builder.addCase(getEmployees.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.message = action.payload;
      state.employees = action.payload;
    });
    builder.addCase(getEmployees.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.error;
    });
    builder.addCase(getEmployee.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    });
    
    builder.addCase(getEmployee.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.singleEmployee = action.payload;
    });
    
    builder.addCase(getEmployee.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.error;
    });    
    // builder.addCase(getEmployee.pending, (state) => {
    //   state.isLoading = true;
    //   state.isSuccess = false;
    //   state.isError = false;
    //   state.message = "";
    // });
    // builder.addCase(getEmployee.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.isSuccess = true;
    //   state.isError = false;
    //   state.message = action.payload;
    //   state.createdEmployee = action.payload;
    // });
    // builder.addCase(getEmployee.rejected, (state, action) => {
    //   state.isLoading = false;
    //   state.isSuccess = false;
    //   state.isError = true;
    //   state.message = action.error;
    // });
    // builder.addCase(getEmployee.pending, (state) => {
    //   state.isLoading = true;
    //   state.isSuccess = false;
    //   state.isError = false;
    //   state.message = "";
    // });
    // builder.addCase(getEmployee.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.isSuccess = true;
    //   state.isError = false;
    //   state.message = action.payload;
    // });
    // builder.addCase(getEmployee.rejected, (state, action) => {
    //   state.isLoading = false;
    //   state.isSuccess = false;
    //   state.isError = true;
    //   state.message = action.error;
    // });
    builder.addCase(resetState, () => initialState);
  },
});

export default employeeSlice.reducer;
