import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";

const getCoupons = async () => {
  const response = await axios.get(`${base_url}coupon/list-all`, config);
  //console.log(response.data);
  return response.data;
};

const getCouponsById = async (id) => {
  const response = await axios.get(`${base_url}coupon/list/${id}`, config);
  //console.log(response.data);
  return response.data;
};

const createCoupon = async (coupon) => {
  const response = await axios.post(`${base_url}coupon/create`, coupon, config);
  //console.log(response.data);
  return response.data;
};

const editCoupon = async (id,coupon) => {
  const response = await axios.post(`${base_url}coupon/edit/${id}`, coupon, config);
  //console.log(response.data);
  return response.data;
};

const couponService = {
  getCoupons,
  getCouponsById,
  editCoupon,
  createCoupon,
};

export default couponService;
