import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { BiEdit, BiTrash } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getEmployees, deleteEmployee } from "../features/employee/employeeSlice";
// import axios from "axios";
// import { base_url } from "../utils/baseUrl";

const columns = [
  {
    title: "#",
    dataIndex: "key",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: "First Name",
    dataIndex: "FirstName",
    sorter: (a, b) => b.FirstName.localeCompare(a.FirstName),
    sortDirections: ["descend"],
  },
  {
    title: "Last Name",
    dataIndex: "LastName",
    sorter: (a, b) => {b.LastName.localeCompare(a.LastName)},
    sortDirections: ["descend"],
  },
  {
    title: "Phone",
    dataIndex: "PhoneNumber",
    // defaultSortOrder: "descend",
    sorter: (a, b) => a.PhoneNumber.length - b.PhoneNumber.length,
    sortDirections: ["descend"],
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];

const EmployeeList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEmployees());
  }, [dispatch]);

  const employeeState = useSelector((state) => state.employee.employees);

  const data1 = [];
  if(employeeState && employeeState.data && employeeState.data.length){
    for (let i = 0; i < employeeState.data.length; i++) {
      data1.push({
        key: i + 1,
        FirstName: employeeState.data[i].FirstName,
        LastName: employeeState.data[i].LastName,
        PhoneNumber: employeeState.data[i].PhoneNumber,
        action: (
          <>
            <Link className="text-danger fs-5" to={`/admin/edit-employee/${employeeState.data[i]._id}`}>
              <BiEdit />
            </Link>
            <span className="text-danger ms-3 fs-5">
              <BiTrash onClick={() => {
                  dispatch(deleteEmployee(employeeState.data[i]._id))
                setTimeout(() => {
                  dispatch(getEmployees())
                }, 1000)
                }}/>
            </span>
          </>
        ),
      });
    }
  }

  return (
    <div>
      <h3 className="mb-4 title">Employees</h3>
      <div>
        <Table columns={columns} dataSource={data1} />
      </div>
    </div>
  );
};

export default EmployeeList;
